import React, { createContext } from 'react'
import PropTypes from 'prop-types';

const AppContext = createContext(null);

const AppProvider = ({ children }) => {

  return (
    <AppContext.Provider value={{}}>
      {children}
    </AppContext.Provider>
  )
};

AppProvider.propTypes = {
  children: PropTypes.node,
};

export default AppContext;

export { AppProvider };
