exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-get-updates-js": () => import("./../../../src/pages/get-updates.js" /* webpackChunkName: "component---src-pages-get-updates-js" */),
  "component---src-pages-idiopathic-hypersomnia-doctor-conversation-starter-js": () => import("./../../../src/pages/idiopathic-hypersomnia-doctor-conversation-starter.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-doctor-conversation-starter-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-managing-idiopathic-hypersomnia-js": () => import("./../../../src/pages/managing-idiopathic-hypersomnia.js" /* webpackChunkName: "component---src-pages-managing-idiopathic-hypersomnia-js" */),
  "component---src-pages-possible-causes-of-idiopathic-hypersomnia-js": () => import("./../../../src/pages/possible-causes-of-idiopathic-hypersomnia.js" /* webpackChunkName: "component---src-pages-possible-causes-of-idiopathic-hypersomnia-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-sleep-quiz-js": () => import("./../../../src/pages/sleep-quiz.js" /* webpackChunkName: "component---src-pages-sleep-quiz-js" */),
  "component---src-pages-what-is-idiopathic-hypersomnia-js": () => import("./../../../src/pages/what-is-idiopathic-hypersomnia.js" /* webpackChunkName: "component---src-pages-what-is-idiopathic-hypersomnia-js" */)
}

